.about-me-header {
  margin-block-end: 10px;
}

.about-me-info {
  padding: 0 13px;
}
h2.about-me-name {
  margin-block-end: 3px;
  margin-block-start: 0;
}
.about-me-status {
  color:rgb(0 0 0 / 60%);
}
