.login-container {
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .logo {
    width: 90%;
  }

  .login-button-container, .text-container, .logo {
    padding: 20px;
  }