.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: Roboto,sans-serif;
}

.App-logo {
  height: 4vmin;
  width: 40vmin;
}

.app-div {
  height: 100%;
}

.App-header {
  padding: 10px;
  margin: 5px 10px 5px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  align-items: center;
  background-color: white;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08), 0 2px 4px 0 rgba(0, 0, 0, 0.12);
}

.right-side-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header-tag{
color: white;
}

.App-link {
  color: #61dafb;
}

.MuiCard-root.box-raised {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  box-shadow: 1px 1px 3px #969696FF;
  padding: 10px;
  border: 0 solid #cbcbcb;
}

.accordion-container {
  margin: 10px
}

.AccordionSummary {
  color: 'black';
}

.container-app{
  background-color: white;
}
